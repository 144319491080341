
<template>
<div>
    <div>
        <app-header />
        <left-sidebar />
        <div class="content-page">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <router-view />
                    </div>
                </div>
            </div>
        </div>
        <app-footer />
    </div>
</div>
</template>

<script>

import AppHeader from '@/components/app/AppHeader.vue'
import LeftSidebar from '@/components/app/LeftSidebar.vue'
import AppFooter from '@/components/app/AppFooter.vue'
export default {
  components: {
    AppHeader,
    LeftSidebar,
    AppFooter
  },
  data() {
    return {

    }
  },
  methods:{
    checkSetNotification(){
      if(this.$route.query.notify){
        let notify = this.$route.query.notify
        if(notify == 'email-verified'){
          this.$store.dispatch("fetchActiveUser")
          this.alertSuccess("Your email address has been verified")
        }
      }
    }
  },
  created() {
    this.checkSetNotification()
  },
}
</script>

<style type="text/css">
  .content-page{
    min-height: 80vh;
    padding: 58px 0px 100px 0px;
    overflow: hidden;
  }
  .font-sm{
    font-size:13px;
  }
  .cursor-pointer{
    cursor: pointer !important;
  }

  .nav-tabs .nav-link.active, .nav-tabs .nav-item.show .nav-link {
    background-color: #f2f3f5;
  }

</style>
